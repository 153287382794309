import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IMailValues } from '../../scripts/ApiTypes';
import { useQuery } from '@tanstack/react-query';
import { postSmartMailTest } from '../../scripts/DataServices';
import { SortingTestResult } from './SortingTestResult';
import { ClipLoader } from 'react-spinners';

export const SortingTestForm = ({ mailboxId }: { mailboxId?: number }) => {
    const [mailData, setMailData] = useState<IMailValues>({
        sender: '',
        recipient: '',
        subject: '',
        body: '',
        attachments: '',
    });

    const testResult = useQuery({ queryKey: ['testResult', mailData], queryFn: () => postSmartMailTest({ ...mailData, mailboxId }), enabled: false });
    return (
        <>
            <Form>
                <Form.Label>Afsender:</Form.Label>
                <Form.Control
                    onChange={(e) => setMailData({ ...mailData, sender: e.currentTarget.value })}
                    value={mailData.sender}
                    placeholder="Indtast afsender"
                ></Form.Control>
                <Form.Label>Modtager:</Form.Label>
                <Form.Control
                    onChange={(e) => setMailData({ ...mailData, recipient: e.currentTarget.value })}
                    value={mailData.recipient}
                    placeholder="Indtast én modtager"
                ></Form.Control>
                <Form.Label>Emnefelt:</Form.Label>
                <Form.Control
                    onChange={(e) => setMailData({ ...mailData, subject: e.currentTarget.value })}
                    value={mailData.subject}
                    placeholder="Indtast emnefelt"
                ></Form.Control>
                <Form.Label>Indhold:</Form.Label>
                <Form.Control
                    onChange={(e) => setMailData({ ...mailData, body: e.currentTarget.value })}
                    as="textarea"
                    placeholder="Indsæt tekst fra mail"
                    value={mailData.body}
                ></Form.Control>
                <Form.Label>Vedhæftninger:</Form.Label>
                <Form.Control
                    onChange={(e) => setMailData({ ...mailData, attachments: e.currentTarget.value })}
                    className="mb-2"
                    placeholder="Indsæt tekst fra vedhæftninger"
                    as="textarea"
                    value={mailData.attachments}
                ></Form.Control>
                <Form.Group>
                    <Button
                        disabled={mailboxId === undefined}
                        onClick={(e) => {
                            e.preventDefault();
                            testResult.refetch();
                        }}
                        className="mr-2"
                    >
                        Indsend oplysninger
                    </Button>
                </Form.Group>
            </Form>
            {testResult.isFetching && <ClipLoader></ClipLoader>}
            {testResult.isError && <p>Der opstod en fejl</p>}
            {testResult.data && <SortingTestResult aiResult={testResult.data.aiResult} ruleHits={testResult.data.ruleHits}></SortingTestResult>}
        </>
    );
};
