import { faSortAlphaDown, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import {
    Row,
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortDirection,
    SortingState,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import { IRule } from '../../scripts/ApiTypes';
import { fetchRuleDetails, updateRuleState } from '../../scripts/DataServices';
import { PaginationButtons } from '../Utility/PaginationButtons';
import { getRuleDescription } from '../RuleCreation/RuleSummation';
import { useSearchParams } from 'react-router-dom';
import { DebouncedInput } from '../Utility/DebouncedInput';
import { BeatLoader } from 'react-spinners';
import { RuleEditButtons } from './RuleEditButtons';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

export const RuleTable = ({ rules }: { rules: IRule[] }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const ruleId = searchParams.get('ruleid');
    const [sorting, setSorting] = useState<SortingState>(() => {
        let searchString = searchParams.get('s');
        if (searchString) {
            return searchString.split('%').map((s) => {
                let a = s.split('$');
                return {
                    id: a[0],
                    desc: a[1] === 'desc',
                };
            });
        } else {
            return [];
        }
    });
    const [globalFilter, setGlobalFilter] = useState(searchParams.get('g') ?? '');

    useEffect(() => {
        let searchString = sorting.map((state) => `${state.id}$${state.desc ? 'desc' : 'asc'}`).join('%');
        searchParams.set('s', searchString);
        searchParams.set('g', globalFilter);
        setSearchParams(searchParams);
    }, [sorting, globalFilter]);

    const columns: ColumnDef<IRule>[] = [
        {
            accessorFn: (row) => row.id.toString(),
            id: 'ruleId',
        },
        {
            accessorFn: (row) => row.name,
            id: 'name',
            header: 'Navn',
        },
        {
            accessorFn: (row) => getRuleDescription(row.ruleLogic),
            id: 'description',
            header: 'beskrivelse',
        },
        {
            accessorFn: (row) => row.state.toString(),
            cell: (props) => {
                // Add on check
                return (
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="w-full h-full flex items-center justify-center"
                        onChange={(e) => {
                            updateRuleState({
                                IdOfRuleToUpdate: props.row.original.id,
                                State: props.row.original.state === 1 ? 2 : 1,
                                ModifiersID: 0,
                            })
                                .then((res) => {
                                    if (res !== 204) {
                                        e.target.checked = props.row.original.state === 1;
                                        window.alert('Der opstod en fejl da reglen skulle ændres');
                                    }
                                })
                                .catch((res) => {
                                    if (res !== 204) {
                                        e.target.checked = props.row.original.state === 1;
                                        window.alert('Der opstod en fejl da reglen skulle ændres');
                                    }
                                });
                        }}
                        defaultChecked={props.row.original.state === 1}
                    />
                );
            },
            id: 'active',
            header: 'Aktiveret',
        },
        {
            cell: (props) => <RuleEditButtons ruleId={props.row.original.id} ruleName={props.row.original.name} />,
            id: 'edit',
            header: 'Rediger',
        },
    ];

    const table = useReactTable({
        data: rules,
        columns,
        initialState: {
            pagination: {
                // @ts-ignore
                pageIndex: +searchParams.get('p') ? +searchParams.get('p') : 0,
                // @ts-ignore
                pageSize: +searchParams.get('ps') ? +searchParams.get('ps') : 10,
            },
            columnVisibility: {
                ruleId: false,
            },
            columnFilters: ruleId
                ? [
                      {
                          id: 'ruleId',
                          value: ruleId,
                      },
                  ]
                : [],
        },
        state: {
            sorting,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    useEffect(() => {
        searchParams.set('p', table.getState().pagination.pageIndex.toString());
        setSearchParams(searchParams);
    }, [table.getState().pagination.pageIndex]);

    useEffect(() => {
        let pageSize = searchParams.get('ps');
        if (pageSize === '10' || pageSize === '25' || pageSize === '50') {
            searchParams.set('ps', table.getState().pagination.pageSize.toString());
        } else {
            searchParams.set('ps', '10');
        }
        setSearchParams(searchParams);
    }, [table.getState().pagination.pageSize]);

    const sortIcon = (sorted: false | SortDirection) => {
        if (!sorted) {
            return '';
        } else if (sorted === 'asc') {
            return <FontAwesomeIcon icon={faSortAlphaDown} />;
        } else {
            return <FontAwesomeIcon icon={faSortAlphaUp} />;
        }
    };

    const resetFilters = () => {
        for (let i of searchParams.keys()) {
            searchParams.delete(i);
        }
        table.resetGlobalFilter();
        table.resetColumnFilters();
        table.resetSorting();
    };

    return (
        <>
            <FormGroup className="flex justify-between mb-2">
                <Form.Group className="flex">
                    <DebouncedInput
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        defaultValue={globalFilter}
                        placeholder={'Søg regler '}
                        className={'w-auto mr-2'}
                    />
                    <Button onClick={resetFilters}>Fjern filtre</Button>
                </Form.Group>
                <Form.Group>
                    <Button href="/rule-creation">Opret regel</Button>
                </Form.Group>
            </FormGroup>
            <div className="grid shadow-sm grid-cols-[30%_50%_repeat(2,_1fr)] mb-2 bg-gray-100">
                <div className="contents">
                    {table.getFlatHeaders().map((header) => (
                        <span
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            className={'bg-main-500 text-gray-100 px-4 py-3 ' + (header.column.getCanSort() ? 'cursor-pointer' : '')}
                        >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {sortIcon(header.column.getIsSorted())}
                        </span>
                    ))}
                </div>
                <Accordion defaultActiveKey={ruleId ? '0' : undefined} className="contents">
                    {table.getRowModel().rows.map((row) => (
                        <>
                            <RowHeader key={row.id + 'h'} eventKey={row.id} row={row}></RowHeader>
                            <Accordion.Collapse key={row.id + 'a'} className="col-span-full px-4 py-2 bg-main-100" eventKey={row.id}>
                                <div>
                                    <TableRow row={row}></TableRow>
                                </div>
                            </Accordion.Collapse>
                        </>
                    ))}
                </Accordion>
            </div>
            <PaginationButtons
                controls={{
                    pageCount: table.getPageCount(),
                    getPageSize: () => table.getState().pagination.pageSize,
                    getCurrentIndex: () => table.getState().pagination.pageIndex,
                    setPageIndex: (i) => table.setPageIndex(i),
                    setPageSize: (i) => table.setPageSize(i),
                }}
            />
        </>
    );
};

const RowHeader = ({ eventKey, row }: { eventKey: string; row: Row<IRule> }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);

    const isOpen = eventKey === activeEventKey;

    const renderButtonCell = () => {
        const cell = row.getVisibleCells().find((c) => c.column.id === 'edit');
        return (
            cell && (
                <span className={'px-4 py-3 border-y border-gray-300 h-full flex items-center ' + (isOpen ? 'bg-main-100' : '')} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </span>
            )
        );
    };

    return (
        <>
            <div className="contents cursor-pointer" onClick={decoratedOnClick}>
                {row.getVisibleCells().map((cell) => {
                    if (cell.column.id !== 'edit')
                        return (
                            <span className={'px-4 py-3 border-y border-gray-300 h-full flex items-center ' + (isOpen ? 'bg-main-100' : '')} key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </span>
                        );
                    else return null;
                })}
            </div>
            {renderButtonCell()}
        </>
    );
};

const TableRow = ({ row }: { row: Row<IRule> }) => {
    const ruleDetails = useQuery({ queryKey: ['ruleDetails', row.original.id], queryFn: () => fetchRuleDetails(row.original.id) });
    return (
        <>
            {ruleDetails.isError && <p>Der opstod en uventet fejl da detaljer om reglen skulle hentes</p>}
            {ruleDetails.isLoading && <BeatLoader />}
            {ruleDetails.data && (
                <table>
                    <colgroup>
                        <col width={'30%'} />
                    </colgroup>
                    <tbody className="[&>tr>td]:p-1">
                        <tr>
                            <td>Navn:</td>
                            <td>{row.original.name}</td>
                        </tr>
                        <tr>
                            <td>Beskrivelse:</td>
                            <td>{row.getValue('description')}</td>
                        </tr>
                        <tr>
                            <td>Sidst redigeret af:</td>
                            <td>{ruleDetails.data && (ruleDetails.data.nameOfLastModifier ?? 'Aldrig redigeret før')}</td>
                        </tr>
                        <tr>
                            <td>Sidst redigeret:</td>
                            <td>
                                {ruleDetails.data &&
                                    (ruleDetails.data.dateLastModified ? new Date(ruleDetails.data.dateLastModified).toLocaleString() : 'Aldrig redigeret før')}
                            </td>
                        </tr>
                        <tr>
                            <td>Oprettet af:</td>
                            <td>{ruleDetails.data && ruleDetails.data.originalCreatorName}</td>
                        </tr>
                        <tr>
                            <td>Oprettet af:</td>
                            <td>{ruleDetails.data && new Date(ruleDetails.data.originalDateCreated).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Regel motorfortsættelse:</td>
                            <td>{row.original.fallthrough ? 'Aktiveret' : 'Deaktiveret'}</td>
                        </tr>
                        <tr>
                            <td>Hovedpostkasse:</td>
                            <td>{ruleDetails.data.mainMailbox.address}</td>
                        </tr>
                        <tr>
                            <td>
                                <a href={'/rule-details/' + ruleDetails.data.id}>Se historik</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </>
    );
};
