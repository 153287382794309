import { fetchAuditLogs, fetchRules } from '../../scripts/DataServices';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { IAuditLogFilter } from '../../scripts/ApiTypes';
import styles from './Auditlog.module.scss';
import AuditLogTable from './AuditlogTable';
import { PaginationButtons } from '../Utility/PaginationButtons';
import { DateRange } from '../Utility/DateRange';
import { DebouncedInput } from '../Utility/DebouncedInput';
import { ClipLoader } from 'react-spinners';
import { useSearchParams } from 'react-router-dom';
import { MailboxDropDown } from '../Utility/MailboxDropDown';

export const AuditLog = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [filters, setFilters] = useState<IAuditLogFilter>(() => {
        // parsing into object to make logical operators not throw errors
        let params = Object.fromEntries(searchParams.entries());
        return {
            //14 days back
            from: params.from ? new Date(params.from) : new Date(Date.now() - 24 * 60 * 60 * 1000 * 14),
            to: params.to ? new Date(params.to) : new Date(Date.now()),
            excludedRules: params.exRules?.split(',').map(Number) ?? [],
            includeAI: params.ai ? !!params.ai : true,
            includeRules: params.rules ? !!params.rules : true,
            includeManualHandling: params.manual ? !!params.manual : true,
            page: params.page ? +params.page : 1,
            sizePerPage: params.pageSize ? +params.pageSize : 10,
            senderContains: params.sender ?? undefined,
            textContains: params.text ?? undefined,
            orderBy: params.by ?? undefined,
            OrderDesc: params.desc === 'false' ? false : true,
            mailboxIds: params.mailboxIds?.split(',').map(Number) ?? [],
        };
    });

    useEffect(() => {
        searchParams.set('from', filters.from.toISOString());
        searchParams.set('to', filters.to.toISOString());
        searchParams.set('exRules', filters.excludedRules.join(','));
        searchParams.set('mailboxIds', filters.mailboxIds.join(','));
        searchParams.set('ai', +filters.includeAI + '');
        searchParams.set('rules', +filters.includeRules + '');
        searchParams.set('manual', +filters.includeManualHandling + '');
        searchParams.set('page', filters.page.toString());
        searchParams.set('pageSize', filters.sizePerPage.toString());
        filters.senderContains && searchParams.set('sender', filters.senderContains);
        filters.textContains && searchParams.set('text', filters.textContains);
        filters.orderBy && searchParams.set('by', filters.orderBy.toString());
        searchParams.set('desc', filters.OrderDesc.valueOf().toString());
        setSearchParams(searchParams);
    }, [filters]);

    const auditLogs = useQuery({ queryKey: ['auditlogs', filters], queryFn: () => fetchAuditLogs(filters), placeholderData: keepPreviousData });

    const ruleNames = useQuery({ queryKey: ['rules'], queryFn: fetchRules });

    const gotToPage = (page: number) => {
        filters.page = page;
        setFilters({ ...filters });
    };

    const makeTypeFilterHeader = () => {
        let list = [];
        if (filters.includeAI) list.push('AI');
        if (filters.includeRules) list.push('Regel');
        if (filters.includeManualHandling) list.push('Manual');
        if (list.length === 0) return 'Ingen';
        return list.join(', ');
    };

    const setSorting = (id: string, desc: boolean) => {
        filters.orderBy = id;
        filters.OrderDesc = desc;
        setFilters({ ...filters });
    };

    return (
        <>
            <div className="page-header">
                <h1>Fremsøgning</h1>
            </div>

            <Form className={styles.filters}>
                <Form.Group>
                    <Form.Label>Vælg periode:</Form.Label>
                    <DateRange
                        to={filters.to}
                        from={filters.from}
                        setTo={(d: Date) => {
                            filters.to = d;
                            setFilters({ ...filters });
                        }}
                        setFrom={(d: Date) => {
                            filters.from = d;
                            setFilters({ ...filters });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Afsender lig med:</Form.Label>
                    <DebouncedInput
                        defaultValue={filters.senderContains}
                        onChange={(e) => {
                            filters.senderContains = e.target.value ? e.target.value : undefined;
                            setFilters({ ...filters });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tekst indeholder:</Form.Label>
                    <DebouncedInput
                        defaultValue={filters.textContains}
                        onChange={(e) => {
                            filters.textContains = e.target.value;
                            setFilters({ ...filters });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Fordelingsmetode:</Form.Label>
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                            variant="outline-secondary"
                            className="w-52 flex justify-between items-center text-black border-gray-400 form-control hover:bg-white"
                        >
                            {makeTypeFilterHeader()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="px-3 w-52">
                            <div className="flex-shrink">
                                <Form.Group>
                                    <Form.Check
                                        type={'checkbox'}
                                        label="AI"
                                        defaultChecked={filters.includeAI}
                                        onChange={(e) => {
                                            filters.includeAI = e.currentTarget.checked;
                                            setFilters({ ...filters });
                                        }}
                                    />
                                    <Form.Check
                                        type={'checkbox'}
                                        label="Regel"
                                        defaultChecked={filters.includeRules}
                                        onChange={(e) => {
                                            filters.includeRules = e.currentTarget.checked;
                                            setFilters({ ...filters });
                                        }}
                                    />
                                    <Form.Check
                                        type={'checkbox'}
                                        label="Manual behandling"
                                        defaultChecked={filters.includeManualHandling}
                                        onChange={(e) => {
                                            filters.includeManualHandling = e.currentTarget.checked;
                                            setFilters({ ...filters });
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Regelfordeling:</Form.Label>
                    <Dropdown autoClose="outside">
                        {ruleNames.data && (
                            <>
                                <Dropdown.Toggle
                                    variant="outline-secondary"
                                    className="w-52 flex justify-between items-center text-black border-gray-400 form-control hover:bg-white"
                                >
                                    {ruleNames.data.length - filters.excludedRules.length == 1
                                        ? ruleNames.data.length - filters.excludedRules.length + ' regel valgt'
                                        : ruleNames.data.length - filters.excludedRules.length + ' regler valgt'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="px-3 w-52">
                                    <div>
                                        <ButtonGroup className="w-full mb-2">
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => {
                                                    filters.excludedRules = [];
                                                    setFilters({ ...filters });
                                                }}
                                            >
                                                Alle
                                            </Button>
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => {
                                                    filters.excludedRules = ruleNames.data.map((rn) => rn.id);
                                                    setFilters({ ...filters });
                                                }}
                                            >
                                                Ingen
                                            </Button>
                                        </ButtonGroup>
                                        {ruleNames.data.map((rn) => (
                                            <Form.Check
                                                key={rn.id}
                                                type={'checkbox'}
                                                label={rn.name}
                                                onChange={(e) => {
                                                    if (e.currentTarget.checked) {
                                                        let i = filters.excludedRules.indexOf(rn.id);
                                                        filters.excludedRules.splice(i, 1);
                                                    } else {
                                                        filters.excludedRules.push(rn.id);
                                                    }
                                                    setFilters({ ...filters });
                                                }}
                                                checked={filters.excludedRules.indexOf(rn.id) < 0}
                                            />
                                        ))}
                                    </div>
                                </Dropdown.Menu>
                            </>
                        )}
                    </Dropdown>
                </Form.Group>
                <MailboxDropDown
                    selectedIds={filters.mailboxIds}
                    onToggle={(e, id) => {
                        if (e.target.checked) {
                            filters.mailboxIds.push(id);
                        } else {
                            let i = filters.mailboxIds.indexOf(id);
                            filters.mailboxIds.splice(i, 1);
                        }
                        setFilters({ ...filters });
                    }}
                ></MailboxDropDown>
            </Form>
            {auditLogs.error && <p>Der opstod en uventet fejl under fremsøgningen</p>}
            {auditLogs.isLoading && (
                <div className="w-full flex justify-center">
                    <ClipLoader size={70} />
                </div>
            )}
            {auditLogs.data?.auditlogs && (
                <>
                    {auditLogs.data.auditlogs.length === 0 ? (
                        <p>Der er ingen resultater for disse filtre</p>
                    ) : (
                        <div className="w-full h-full relative">
                            {auditLogs.isFetching && <ClipLoader className="absolute z-10 m-auto left-0 right-0 top-0 bottom-0" size={70} />}
                            <AuditLogTable
                                setControlledSorting={setSorting}
                                className={auditLogs.isFetching ? 'opacity-50' : undefined}
                                data={auditLogs.data.auditlogs}
                                initialState={filters.orderBy ? { id: filters.orderBy, desc: filters.OrderDesc } : undefined}
                            ></AuditLogTable>
                            <div className="flex justify-between">
                                <PaginationButtons
                                    controls={{
                                        pageCount: auditLogs.data.pagination.pageCount,
                                        getPageSize: () => filters.sizePerPage,
                                        getCurrentIndex: () => filters.page - 1,
                                        setPageIndex: (i) => gotToPage(i + 1),
                                        setPageSize: (i) => {
                                            filters.sizePerPage = i;
                                            setFilters({ ...filters });
                                        },
                                    }}
                                />
                                <Button className="h-min" href={`/api/auditlogs/download?${searchParams.toString()}`}>
                                    Download som CSV
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
